import React, { useState } from 'react';
import ReactDOM from "react-dom/client";
import InConstruct from '../components/InConstruct/InConstruct';
import FistPublicyRoute from '../components/FirstPublicy/FirstPublicyRoute';
import SecondPublicyRoute from '../components/SecondPublicy/SecondPublicyRoute';
import ThirdLanding from '../components/ThirdPublicy/ThirdLanding.jsx';
import ThirdLandingEmail from '../components/ThirdPublicy/ThirdLandingEmail.jsx';
import Thanks from '../components/Thanks/Thanks'
import Tiktok from '../components/ThirdPublicy/ThirdLandingTiktok'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import FourthLandingPage from '../pages/FourthLandingPage.jsx';

const First = () => {
  const [pathname, setPathname] = useState('');

  useLocation((location) => {
    setPathname(location.pathname);
  });

  return (
      <Routes>
        {/* Página de inicio */}
        <Route path="/" element={<InConstruct to="/general" />} />
        {/* Páginas públicas */}
        <Route path="/general" element={<FistPublicyRoute />} />
        <Route path="/kickstarter" element={<SecondPublicyRoute />} />
        {/* Páginas privadas */}
        <Route path="/about" element={<ThirdLanding />} />
        <Route path="/kick" element={<ThirdLandingEmail />} />
        <Route path="/kickprueba" element={<ThirdLandingEmail />} />
        <Route path="/thx" element={<Thanks />} />
        <Route path="/tiktok" element={<Tiktok />} />

        {/* Nueva */}
        <Route path="/landing" element={<FourthLandingPage/>} />
      </Routes>
  );
};

export default First;