import React from 'react'
import './firstpublicy.css'
const FirstPublicyRoute = () => {

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   const API_URL = "https://rose-tasty-abalone.cyclic.app/api/auth/register"

  //   axios.post(API_URL, { fixedNumber: number })
  //     .then(response => {
  //       console.log("respuesta de la api:", response.data)
  //       setAlert('Actualizado con exito')
  //     }).catch(error => {
  //       console.error("Error al enviar el número:", error);
  //     });
  // }


  return (
    <div className='container_firstpublicyrouter'>
      <img className='zelcar-logo-top' src="./first/portada.png" alt='Zelcar logo'></img>
      <h1 className='firstpublicy_title'>Get Ready for Secret Forest</h1>
      <h2 className='firstpublicy_subtitle'>by Zelcar Games</h2>
      <p className='firstpublicy_paragraph'> Can we count on your pledge for our upcoming project?</p>

      <div className="signup">
        <label>Add your email address</label>
        <div>
          <input className='input_email' type='email' placeholder='name@example.com'></input>
          <input className='input_submit' type="submit" value="Sign up" />
        </div>
      </div>
      <p className='input-paragraph'>Join 16 other people!</p>

      <div className='containerOne'>
        <h3 className='title_freerewards'>Early birds will have an additional discount on each reward, sign up now and don't miss your chance!</h3>

        <p className='containerOne_subtitle'>
          Welcome to <b>Secret Forest</b>, an enchanting <b>open-world MMORPG</b> that immerses players
          in a boundless<b> fantasy universe.</b> Prepare to embark on a thrilling journey filled with won-ders and <b>mesmerizing races,</b> as you take on epic adversaries in your quest for glory.
        </p>

        <img className='zelcar-logo-middle' src="./first/Portada-SecretForest.png"></img>
      </div>

      <div>
        <p className='paragraph-bottom-logo'>
          In this captivating <b>MMORPG,</b> you'll have the unique opportunity to align yourself with
          unprecedented factions. Forge strong alliances, engage in grand battles, and conquer
          territories to establish your faction's dominance. <b>Uncover ancient secrets</b> and<b> unlock
            potent abilities </b>to shape your character into the legendary hero of the Secret Forest.
        </p>
        <img className='alufis-image' src="./first/alufis.png"></img>

        <p className='paragraph-bottom-logo'>
          Beyond traditional roles, Secret Forest offers exciting <b>new professions</b> to explore. As a
          <b> necromancer,</b> you can seek aid from spirits to enhance your lands, animals, and crops.
          Alternatively, delve into <b>the art of fungiculture</b> to cultivate powerful mushrooms, boosting
          your combat skills and profession management.
        </p>

        <p className='paragraph-bottom-logo'>
          Crafting takes center stage in Secret Forest, allowing you to create <b>delectable recipes,
            exquisite jewelry, and formidable weapons </b>to trade in the bustling in-game markets.
          Each<b> weapon is imbued </b>with elemental powers like<b> fire, ice, or poison,</b> adding strategic
          depth to battles as you adapt to your enemies' <b>strengths and weaknesses </b>while donning
          armor to withstand the diverse forces of nature.
        </p>
      </div>
      <p className='firstpublicy_paragraph'> Can we count on your pledge for our upcoming project?</p>
      <div className="signup">
        <label>Add your email address</label>
        <div>
          <input className='input_email' type='email' placeholder='name@example.com'></input>
          <input className='input_submit' type="submit" value="Sign up" />
        </div>
      </div>
      <p className='input-paragraph'>Join 16 other people!</p>

      <p className='paragraph-bottom-logo'>
        Prepare to be captivated by the expansive, <b>magical realm</b> of Secret Forest. Explore
        awe-inspiring locations such as the <b>valley of the elves,</b> the <b>swamp of the axolots,</b> the
        <b>plains of the foxys,</b> the <b>lava mountains of the embears,</b> the <b>silver hills of the fairans</b>, and
        countless other beautiful biomes inhabited by magical races of <b>light and darkness. </b>
        Engage in thrilling combat, honing your agility to master the movements of diverse enemies you'll encounter
      </p>

      <img className='zelcar-logo-bottom' src='./first/bioma1.png'></img>

      <p className='paragraph-bottom-logo'> The lore of Secret Forest traces back to the ten gods who each created a race to
        represent them. While peace and harmony once prevailed, the arrival of day and night
        altered the course of history. The races now align themselves with the <b>messiahs of light,
        Brightlight, or the messiah of darkness, Darkan,</b>  each vying for <b>supremacy</b> over all.</p>

      <img className='zelcar-logo-map' alt='map' src="./first/mapa.png"></img>

      <p className='paragraph-bottom-logo'>Embark on an <b>unforgettable odyssey.</b> in Secret Forest, where destiny beckons and
        magical wonders await your discovery. Are you ready to step into this epic realm and
        make your mark among the heroes of legend?</p>

      <h3 className='title_freerewards width padding'>
        Don't miss out on being among the first to
        receive campaign updates and enjoy exclusive early bird discounts for backers. Act
        swiftly, as these limited offers won't last long!
      </h3>

      <p className='firstpublicy_paragraph'> Can we count on your pledge for our upcoming project?</p>
      <div className="signup">
        <label>Add your email address</label>
        <div>
          <input className='input_email' type='email' placeholder='name@example.com'></input>
          <input className='input_submit' type="submit" value="Sign up" />
        </div>
      </div>
      <p className='input-paragraph'>Join 16 other people!</p>

      <div className='padding-bottom'></div>
    </div>
  )
}

export default FirstPublicyRoute