import React, { useEffect } from "react"
import ProgressBar from "@ramonak/react-progress-bar";
import './index.css';
function App() {

  const kickstarteRedirect = () => {
    window.location.href = ('https://www.kickstarter.com/projects/secretforest/secretforest')
    console.log("hola")
  }

  useEffect(() => {
    window.location.href = "https://secretforestworld.com/landing/en"
  }, [])
  
  return (
    <div className="App">
      <main className="container-construct">
        <img  className="logo_absolute"src="./images/logo.png"></img>
        <div className="titles">
          <h1 className="construct-title">UNDER</h1>
          <h2 className="construct-subtitle">CONSTRUCTION</h2>
        </div>
        <p className="text_notify">Site ready</p>
        <div className="progressbar">
          <ProgressBar
            completed=""
            className="wrapper"
            barContainerClassName="container"
            completedClassName="barCompleted"
          />
          <div className="percents">
            <p>0%</p>
            <p>100%</p>
          </div>
        </div>
        
        <div onClick={kickstarteRedirect} href="https://www.kickstarter.com/projects/secretforest/secretforest" className="kickstarter">
          <img  src="./images/kickstarter.svg"></img>
          <a className="button_kickstarter2">Know the project on kickstarter</a>
        </div>

        <div className="social">
          <a href="https://instagram.com/secretforestworld?igshid=MzRlODBiNWFlZA==" className="social_single">
            <img src="./images/instagram.svg"></img>
          </a>
          <a href="https://discord.gg/wwf6XgeHKh" className="social_single">
            <img src="./images/discord.svg"></img>
          </a>
          <a href="https://www.youtube.com/@secretforestworld6141" className="social_single">
            <img src="./images/youtube.svg"></img>
          </a>
          <a href="https://www.tiktok.com/@secretforestworld?_t=8eNIa0XgpIT&_r=1" className="social_single">
            <img src="./images/tiktok.svg"></img>
          </a>
          <a href="https://www.facebook.com/profile.php?id=100086670464045&mibextid=LQQJ4d" className="social_single">
            <img className="fb" src="./images/facebook.svg"></img>
          </a>
        </div>
      </main>
    </div>
  );
}

export default App;
