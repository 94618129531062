import React, { useEffect, useState } from 'react'
import './thirdpublicy.css'
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import ModalImage from "react-modal-image";
import { Autoplay, Pagination, Navigation, EffectCards } from 'swiper/modules';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Icon } from '@iconify/react';
import ReCAPTCHA from "react-google-recaptcha";

const ThirdLanding = () => {

    const [emailFront, setEmailFront] = useState('');
    const [captchaState, setCaptchaState] = useState(false);
    const [fixedNumber, setFixedNumber] = useState(null);
    const [inputSubmit, setInputSubmit] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault()
        if (captchaState && isValidEmail(emailFront)) {
            const API_URL = "https://rose-tasty-abalone.cyclic.app/api/auth/register"

            axios.post(API_URL, { email: emailFront })
                .then(response => {
                    toast.success('Email Registered!', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setEmailFront('')
                    setFixedNumber(fixedNumber + 1)
                    setTimeout(() => {
                        window.location.href = "https://secretforest.world/#/thx";
                    }, 1500);
                }).catch(error => {
                    console.error("Error al enviar el número:", error);

                    toast.error('The email is already registered', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                });
        } else {
            toast.error('Verification failed', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }
    }

    useEffect(() => {
        fetchFixedNumber();
    }, []);

    useEffect(() => {
        if (captchaState && isValidEmail(emailFront)) {
            setInputSubmit(true)
        }
    },)
    const fetchFixedNumber = () => {
        const API_URL = "https://rose-tasty-abalone.cyclic.app/api/auth/cuantity";

        axios.get(API_URL)
            .then(response => {
                setFixedNumber(response.data.emailCuantity);
            })
            .catch(error => {
            });
    };
    const kickstarteRedirect = () => {
        window.location.href = ('https://www.kickstarter.com/projects/secretforest/secretforest')
    }

    const isValidEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    }


    function onChange(value) {
        console.log("Captcha value:", value);
        setCaptchaState(true)

        if (isValidEmail(emailFront)) {
            setInputSubmit(false);
        }

    }

    function disabledClick(e) {
        e.preventDefault()
        toast.error('Complete the fields first', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        })
    }


    // Facebook 

    // const FetchPixelFacebook = () => {
    //     facebookToken = 'EAALK14an38ABO5Relz2tfmrdsm8RYut73ru1ptHxBxzE827ZAEYf3m7zbgSk0esS9o1OwvmV4phm3MvNThzD1sZA7JfxZCpFRN53wzzrSWiAZAOJ2C6JBKZAAxANDZBDG6kONAOVgwdFJjEUNxvgQIZBsyZBnjBdHZBlJaPRrnRbyhhbOVSQkTta7WttA8kYt1TPW7AZDZD'
    //     // 2034324650164263 pixel id

    //     //https://graph.facebook.com/v18.0/{PIXEL_ID}/events?access_token={TOKEN}
    // }
    return (
        <>
            <div className='container-third'>
                <div className="container-third_banner"></div>
                <h1 className='third_subtitle'>Get Ready for Secret Forest</h1>
                <h1 className='third_subsubtitle'>By Zelcar Games</h1>

                <section className='third-padding-top'>

                    <section className='third_email-section'>
                        <p>Can we count on your pledge for our upcoming project?</p>
                        {inputSubmit ? (
                            <form onSubmit={handleSubmit}>
                                <label>Add your email address</label>
                                <div>
                                    <input value={emailFront} onChange={(event) => setEmailFront(event.target.value)} type='email' placeholder='name@example.com'></input>

                                    <input type='submit' value="Sign up"></input>
                                </div>
                                <p>Join {fixedNumber} other people!</p>
                            </form>
                        ) : (
                            <form>
                                <label>Add your email address</label>
                                <div>
                                    <input value={emailFront} onChange={(event) => setEmailFront(event.target.value)} type='email' placeholder='name@example.com'></input>
                                    <input disabled type='submit' className='disabled-button' value="Sign up"></input>
                                </div>
                                <p>Join {fixedNumber} other people!</p>
                            </form>
                        )}

                        <ReCAPTCHA
                            sitekey="6LcKs2MoAAAAANbEb8FgM_zGq-AZx2SegfCCegkn"
                            onChange={onChange}
                        />
                    </section>
                </section>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
                <main>
                    <section className='third_videos_container'>
                        <div className='third-videos'>
                            <iframe width="660" height="375" src="https://www.youtube.com/embed/83HM1vGdp4I?si=Dm6ksqmesj4E21fk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </section>

                    <div className='third_races_container'>
                        <div className='sub-container_races'>
                            <p className='races_title'>Discover a vibrant realm brimming with wonders, where incredible races come to life. Master a <b>unique combat system</b> and <b>challenge epic foes in your quest for glory.</b></p>
                            <section className='races_characters-container'>
                                <div className='third_cards'>
                                    <Icon icon="ic:baseline-arrow-back-ios-new" />

                                    <Swiper
                                        effect={'cards'}
                                        grabCursor={true}
                                        className="mySwiper"
                                        modules={[EffectCards]}
                                    >
                                        <SwiperSlide><img className='races_img' src="./third/png/races_aquans.png"></img></SwiperSlide>
                                        <SwiperSlide><img className='races_img' src="./third/png/races_axolotl.png"></img></SwiperSlide>
                                        <SwiperSlide><img className='races_img' src="./third/png/races_elves.png"></img></SwiperSlide>
                                        <SwiperSlide><img className='races_img' src="./third/png/races_embear.png"></img></SwiperSlide>
                                        <SwiperSlide><img className='races_img' src="./third/png/races_fairans.png"></img></SwiperSlide>
                                        <SwiperSlide><img className='races_img' src="./third/png/races_foxys.png"></img></SwiperSlide>
                                        <SwiperSlide><img className='races_img' src="./third/png/races_trolls.png"></img></SwiperSlide>
                                        <SwiperSlide><img className='races_img' src="./third/png/races_goblins.png"></img></SwiperSlide>
                                    </Swiper>
                                    <Icon icon="ic:baseline-arrow-forward-ios" />
                                </div>
                                <div className='third_cards-static'></div>
                                <div className='races_text-right'>
                                    <div className='races-format'>
                                        <p className='races_number'>8</p>
                                        <div className='races_text-left'>
                                            <p className='races_text work-sans'>unique magical</p>
                                            <div className='races_to-play'>
                                                <p className='races_text text-bork beige'>Races</p>
                                                <small>to <br></br> play</small>
                                            </div>
                                        </div>
                                    </div>
                                    <p className='each-race work-sans'>Each race has <br></br> special attributes, abilities, <br></br> and passive skills</p>
                                </div>
                            </section>
                        </div>
                        <p class="third_races_bottom-text dorado work-sans">You become what you specialize in: There won't be a predetermined class system; <b>you determine your class through your upgrades, armor, and weapons.</b> </p>
                    </div>

                    <section className='third_biomes_container'>
                        <div className="biomes_text">
                            <p className='third_biomes_number beige bork'>10</p>
                            <div className='biomes_text-right'>
                                <p className='biomes_text-right-p white work-sans bold'>completely unique</p>
                                <p className='biomes_text-right-p bork beige'>Fantasy biomes</p>
                            </div>
                        </div>
                        <img src="./third/png/map.png"></img>
                        <p className='third_biomes-bottom-text'>Private property to <b className='beige'>use and decorate as you like:</b> Create an alchemy potion bar, use your lake for fish farming, have the biggest harvest, and more!</p>
                    </section>
                    <section className='third_consoles'>
                        <img src="./third/png/consolasgrandea.png" alt="" />
                    </section>

                    <section className='third_lands_container'>
                        <p className='third_lands-title beige bork bold'>MIXED <br></br> SKILL TREES <span className='work-sans'>of</span></p>
                        <p className='third_lands-subtitle dorado'>elements, weapons and professions.</p>
                        <div className='third_lands-img-container'>
                            <div className="skill-tree_div">
                                <img src="./third/png/skill_tree.png" alt="" />
                            </div>
                            <small className='third_lands-skilltree_small morado'>First concept of skill three</small>
                        </div>
                    </section>

                    <section className='third_open-world'>
                        <img src="./third/monitor/landing_08.png" alt="" />
                    </section>
                    <section className='third_gameplay'>
                        <img src="./third/movil/joystick.png" alt="" />
                    </section>


                    <section className='third_elements_png'>
                    </section>

                    <section className='third_originalstory'>

                    </section>

                </main>
            </div >

            <div className='third-bottom_container'>

                <section className='third_email-section'>
                    <p>Can we count on your pledge for our upcoming project?</p>
                    {inputSubmit ? (
                        <form onSubmit={handleSubmit}>
                            <label>Add your email address</label>
                            <div>
                                <input value={emailFront} onChange={(event) => setEmailFront(event.target.value)} type='email' placeholder='name@example.com'></input>

                                <input type='submit' value="Sign up"></input>
                            </div>
                            <p>Join {fixedNumber} other people!</p>
                        </form>
                    ) : (
                        <form>
                            <label>Add your email address</label>
                            <div>
                                <input value={emailFront} onChange={(event) => setEmailFront(event.target.value)} type='email' placeholder='name@example.com'></input>
                                <div onClick={disabledClick}>
                                    <input disabled type='submit' className='disabled-button' value="Sign up"></input>
                                </div>
                            </div>
                            <p>Join {fixedNumber} other people!</p>
                        </form>
                    )}
                    <ReCAPTCHA
                        sitekey="6LcKs2MoAAAAANbEb8FgM_zGq-AZx2SegfCCegkn"
                        onChange={onChange}
                    />
                </section>
                <section className='third_get-ready'>
                    <p className='negro'>Get Ready for Secret Forest</p>
                </section>

                <footer>
                    All Right Reserved - Zelcar Games
                </footer>
            </div>
        </>
    )
}

export default ThirdLanding