import React, { useEffect, useState } from "react";
import "./thirdpublicy.css";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import ModalImage from "react-modal-image";
import { Autoplay, Pagination, Navigation, EffectCards } from "swiper/modules";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@iconify/react";
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from "react-ga";
import { Resend } from "resend";
const ThirdLanding = () => {
  const [emailFront, setEmailFront] = useState("");
  const [captchaState, setCaptchaState] = useState(false);
  const [fixedNumber, setFixedNumber] = useState(null);
  const [inputSubmit, setInputSubmit] = useState(false);
  const [modalState, setModalState] = useState(false);
  useEffect(() => {
    fetchFixedNumber();
  }, []);

  useEffect(() => {
    if (captchaState && isValidEmail(emailFront)) {
      setInputSubmit(true);
    }
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (captchaState && isValidEmail(emailFront)) {
      const API_URL =
        "https://hammerhead-app-i4xs5.ondigitalocean.app/api/auth/register";

      axios
        .post(API_URL, { email: emailFront })
        .then((response) => {
          sendEmail(emailFront);
        })
        .catch((error) => {
          console.error("Error al enviar el número:", error);

          toast.error("The email is already registered", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    } else {
      toast.error("Verification failed", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const fetchFixedNumber = () => {
    const API_URL =
      "https://hammerhead-app-i4xs5.ondigitalocean.app/api/auth/cuantity";

    axios
      .get(API_URL)
      .then((response) => {
        setFixedNumber(response.data.emailCuantity + 18450);
      })
      .catch((error) => {});
  };
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  const sendEmail = async (email) => {
    axios
      .post(
        "https://hammerhead-app-i4xs5.ondigitalocean.app/api/secretForestEmails/sendEmail",
        { emailTo: email }
      )
      .then((res) => {
        toast.success("Thank you for signing up!", {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        // setTimeout(() => {
        //   toast.info(
        //     "Now sign up for Kickstarter to be among the first to know about the campaign launch!",
        //     {
        //       position: "top-center",
        //       autoClose: 4000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //       theme: "dark",
        //     }
        //   );
        // }, 2000);
        setTimeout(() => {
          setModalState(true);
          // }, 4000);
        }, 1000);
        setEmailFront("");
        fetchFixedNumber();
        setTimeout(() => {
          window.location.href =
            "https://www.kickstarter.com/projects/secretforest/secretforest";
        }, 7500);
      })
      .catch((error) => {
        toast.error("Internal server error sending email!", {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.error(error);
      });
  };
  function onChange(value) {
    console.log("Captcha value:", value);
    setCaptchaState(true);

    if (isValidEmail(emailFront)) {
      setInputSubmit(false);
    }
  }

  const resend = new Resend("re_L2HfsTFs_Pc1kTYjJpgzf3awusFJGcW2V");

  const functionLoquita = async (req, res) => {
    try {
      const data = await resend.emails.send({
        from: "Acme <onboarding@resend.dev>",
        to: ["delivered@resend.dev"],
        subject: "Hello world",
        react: emailTemplate({ firstName: "Jhon" }),
      });

      res.status(200).json(data);
    } catch (error) {
      res.status(400).json(error);
    }
  };

  const emailTemplate = ({ firstName }) => (
    <div>
      <h1>Welcome {firstName}</h1>
    </div>
  );

  return (
    <main
      className={
        modalState == true ? "mainContainer activeMountModal" : "mainContainer"
      }
    >
      {/*
      {modalState == true && (
        <>
          <div className="modalBackground"></div>
          <div className="mountModalContainer">
            <button
              className="mountModalCloseBtn"
              onClick={() => setModalState(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 32 32"
              >
                <path
                  fill="#523737"
                  d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m0 26C9.4 28 4 22.6 4 16S9.4 4 16 4s12 5.4 12 12s-5.4 12-12 12"
                />
                <path
                  fill="#523737"
                  d="M21.4 23L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"
                />
              </svg>
            </button>
            <div className="mountModalRight">
              <img src="/images/mountPopUp/gatocaballo.png" alt="Mount" />
            </div>
            <div className="mountModalLeft">
              <p className="mountModalText">
                <strong>
                  Get the exclusive pre-lauch campaign mount in just two steps:
                </strong>
              </p>
              <p className="mountModalText">
                <img src="/images/mountPopUp/flecha.png" alt="" />
                Sign up for our projects Kickstarter waitlist.
              </p>
              <p className="mountModalText">
                <img src="/images/mountPopUp/flecha.png" alt="" />
                Join our Discord and show a screeshot of your Kickstarter
                waitlist registration.
              </p>
              <div className="mountModalButtons">
                <a href="https://www.kickstarter.com/projects/secretforest/secretforest">
                  <img
                    src="/images/mountPopUp/boton_kickstarter.png"
                    alt="Kickstarter button"
                  />
                </a>
                <a href="https://discord.com/invite/wwf6XgeHKh">
                  <img
                    src="/images/mountPopUp/boton_discord.png"
                    alt="Discord button"
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    */}
      {modalState == true && (
        <>
          <div className="modalBackground"></div>
          <div className="mountModalContainer">
            <button
              className="mountModalCloseBtn"
              onClick={() => setModalState(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 32 32"
              >
                <path d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m0 26C9.4 28 4 22.6 4 16S9.4 4 16 4s12 5.4 12 12s-5.4 12-12 12" />
                <path d="M21.4 23L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z" />
              </svg>
            </button>
            <img
              src="/images/mountPopUp/halcon.png"
              alt="Mount"
              className="mountModalImage"
            />
            <p className="mountModalText">
              <strong>
                Get the exclusive pre-launch Hippogriff mount in just two steps:
              </strong>
            </p>
            <p className="mountModalText2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M10 8.64v6.72L15.27 12z"
                  opacity=".3"
                />
                <path
                  fill="currentColor"
                  d="m8 19l11-7L8 5zm2-10.36L15.27 12L10 15.36z"
                />
              </svg>
              First Step: Sign up for our Kickstarter waitlist to be among the
              first to know when the campaign launches! You are
              being redirected.
            </p>
            {/* <p className="mountModalText2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M10 8.64v6.72L15.27 12z"
                  opacity=".3"
                />
                <path
                  fill="currentColor"
                  d="m8 19l11-7L8 5zm2-10.36L15.27 12L10 15.36z"
                />
              </svg>
              Join our Discord and show a screeshot of your Kickstarter waitlist
              registration.
            </p> */}
            {/* <div className="mountModalButtons">
              <a href="https://www.kickstarter.com/projects/secretforest/secretforest">
                <img
                  src="/images/mountPopUp/boton_kickstarter.png"
                  alt="Kickstarter button"
                />
              </a>
              <a href="https://discord.com/invite/wwf6XgeHKh">
                <img
                  src="/images/mountPopUp/boton_discord.png"
                  alt="Discord button"
                />
              </a>
            </div> */}
          </div>
        </>
      )}

      <div className="container-third">
        <div className="container-third_banner">
          {/* POPPOPS MONTURE HTML */}
          <div class="container-banner" id="containerbanner">
            <div class="left-Container"></div>
          </div>
        </div>

        <h1 className="third_subtitle">Get Ready for Secret Forest</h1>
        <h1 className="third_subsubtitle">By Zelcar Games</h1>
        <section className="third-padding-top">
          <section className="third_email-section">
            <p>Can we count on your pledge for our upcoming project?</p>
            {inputSubmit ? (
              <form onSubmit={handleSubmit}>
                <label>Add your email address</label>
                <div>
                  <input
                    value={emailFront}
                    onChange={(event) => setEmailFront(event.target.value)}
                    type="email"
                    placeholder="name@example.com"
                  ></input>

                  <input type="submit" value="Sign up"></input>
                </div>
                <p>Join {fixedNumber} other people!</p>
              </form>
            ) : (
              <form>
                <label>Add your email address</label>
                <div>
                  <input
                    value={emailFront}
                    onChange={(event) => setEmailFront(event.target.value)}
                    type="email"
                    placeholder="name@example.com"
                  ></input>
                  <input
                    disabled
                    type="submit"
                    className="disabled-button"
                    value="Sign up"
                  ></input>
                </div>
                <p>Join {fixedNumber} other people!</p>
              </form>
            )}
            <ReCAPTCHA
              sitekey="6LcKs2MoAAAAANbEb8FgM_zGq-AZx2SegfCCegkn"
              onChange={onChange}
            />
          </section>
        </section>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <main>
          <div className="third_races_container">
            <div className="sub-container_races">
              <p className="races_title">
                Discover a vibrant realm brimming with wonders, where incredible
                races come to life. Master a <b>unique combat system</b> and{" "}
                <b>challenge epic foes in your quest for glory.</b>
              </p>
              <section className="races_characters-container">
                <div className="third_cards">
                  <Icon icon="ic:baseline-arrow-back-ios-new" />

                  <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    className="mySwiper"
                    modules={[EffectCards]}
                  >
                    <SwiperSlide>
                      <img
                        className="races_img"
                        src="./third/png/races_aquans.png"
                      ></img>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="races_img"
                        src="./third/png/races_axolotl.png"
                      ></img>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="races_img"
                        src="./third/png/races_elves.png"
                      ></img>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="races_img"
                        src="./third/png/races_embear.png"
                      ></img>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="races_img"
                        src="./third/png/races_fairans.png"
                      ></img>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="races_img"
                        src="./third/png/races_foxys.png"
                      ></img>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="races_img"
                        src="./third/png/races_trolls.png"
                      ></img>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        className="races_img"
                        src="./third/png/races_goblins.png"
                      ></img>
                    </SwiperSlide>
                  </Swiper>
                  <Icon icon="ic:baseline-arrow-forward-ios" />
                </div>
                {/* <div className="third_cards-static"></div> */}
                <div className="races_text-right">
                  <div className="races-format">
                    <p className="races_number">8</p>
                    <div className="races_text-left">
                      <p className="races_text work-sans">unique magical</p>
                      <div className="races_to-play">
                        <p className="races_text text-bork beige">Races</p>
                        <small>
                          to <br></br> play
                        </small>
                      </div>
                    </div>
                  </div>
                  <p className="each-race work-sans">
                    Each race has <br></br> special attributes, abilities,{" "}
                    <br></br> and passive skills
                  </p>
                </div>
              </section>
            </div>
            <p class="third_races_bottom-text dorado work-sans">
              You become what you specialize in: There won't be a predetermined
              class system;{" "}
              <b>
                you determine your class through your upgrades, armor, and
                weapons.
              </b>{" "}
            </p>
          </div>

          <section className="third_biomes_container">
            <div className="biomes_text">
              <p className="third_biomes_number beige bork">10</p>
              <div className="biomes_text-right">
                <p className="biomes_text-right-p white work-sans bold">
                  completely unique
                </p>
                <p className="biomes_text-right-p bork beige">Fantasy biomes</p>
              </div>
            </div>
            <img src="./third/png/map.png"></img>
            <p className="third_biomes-bottom-text">
              Private property to{" "}
              <b className="beige">use and decorate as you like:</b> Create an
              alchemy potion bar, use your lake for fish farming, have the
              biggest harvest, and more!
            </p>
          </section>
          <section className="third_consoles">
            <img src="./third/png/consolasgrandea.png" alt="" />
          </section>
          {/* <section className='third_videos_container'>
        <p className='white'>Private property to <b className='beige'>use and decorate as you like: </b> Create an alchemy potion bar, use your lake for fish farming, have the biggest harvest, and more!</p>
        <div className='third-videos'>
        <ModalImage
        small={'./third/monitorsintexto/landing_02b.png'}
        large={'/third/monitorsintexto/landing_02b.png'}
        alt="Hello World!"
        />;
        <div className='video'></div>
        <div className='video'></div>
        </div>
      </section> */}

          <section className="third_lands_container">
            <p className="third_lands-title beige bork bold">
              MIXED <br></br> SKILL TREES <span className="work-sans">of</span>
            </p>
            <p className="third_lands-subtitle dorado">
              elements, weapons and professions.
            </p>
            <div className="third_lands-img-container">
              <div className="skill-tree_div">
                <img src="./third/png/skill_tree.png" alt="" />
              </div>
              <small className="third_lands-skilltree_small morado">
                First concept of skill three
              </small>
            </div>
          </section>

          <section className="third_open-world">
            <img src="./third/monitor/landing_08.png" alt="" />
          </section>
          <section className="third_gameplay">
            <img src="./third/movil/joystick.png" alt="" />
          </section>

          <section className="third_elements_png"></section>

          <section className="third_originalstory"></section>
        </main>
      </div>

      <div className="third-bottom_container">
        <section className="third_email-section">
          <p>Can we count on your pledge for our upcoming project?</p>
          {inputSubmit ? (
            <form onSubmit={handleSubmit}>
              <label>Add your email address</label>
              <div>
                <input
                  value={emailFront}
                  onChange={(event) => setEmailFront(event.target.value)}
                  type="email"
                  placeholder="name@example.com"
                ></input>

                <input type="submit" value="Sign up"></input>
              </div>
              <p>Join {fixedNumber} other people!</p>
            </form>
          ) : (
            <form>
              <label>Add your email address</label>
              <div>
                <input
                  value={emailFront}
                  onChange={(event) => setEmailFront(event.target.value)}
                  type="email"
                  placeholder="name@example.com"
                ></input>
                <input
                  disabled
                  type="submit"
                  className="disabled-button"
                  value="Sign up"
                ></input>
              </div>
              <p>Join {fixedNumber} other people!</p>
            </form>
          )}
          <ReCAPTCHA
            sitekey="6LcKs2MoAAAAANbEb8FgM_zGq-AZx2SegfCCegkn"
            onChange={onChange}
          />
        </section>
        <a href="https://discord.com/invite/wwf6XgeHKh" className="downDiscord">
          <img
            src="/images/mountPopUp/boton_discord.png"
            alt="Discord button"
          />
        </a>
        <section className="third_get-ready">
          <p className="negro">Get Ready for Secret Forest</p>
        </section>

        <footer>All Right Reserved - Zelcar Games</footer>
      </div>
    </main>
  );
};

export default ThirdLanding;
