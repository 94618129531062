import React, { useEffect, useState } from 'react'
import './thirdpublicy.css'
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import ModalImage from "react-modal-image";
import { Icon } from '@iconify/react';


import { Autoplay, Pagination, Navigation, EffectCards } from 'swiper/modules';

const ThirdLanding = () => {

  const [fixedNumber, setFixedNumber] = useState(null);

  useEffect(() => {
    fetchFixedNumber();
  }, []);

  const fetchFixedNumber = () => {
    const API_URL = "https://rose-tasty-abalone.cyclic.app/api/auth/getFixedNumber";

    axios.get(API_URL)
      .then(response => {
        setFixedNumber(response.data.fixedNumber);
      })
      .catch(error => {
        console.error("Error al obtener el número:", error);
      });
  };

  const kickstarteRedirect = () => {
    window.location.href = ('https://www.kickstarter.com/projects/secretforest/secretforest')
  }

  return (
    <>
      <div className='container-third'>
        <div className="container-third_banner"></div>
        <h1 className='third_subtitle'>Get Ready for Secret Forest</h1>
        <h1 className='third_subsubtitle'>By Zelcar Games</h1>

        {/* <section className='third-input_email top-push'>
        <form>
        <label>Add your email address</label>
        <div>
        <input type='email' placeholder='name@my_emailaddress.com'></input>
        <input type='submit' value="Sign up"></input>
        </div>
        <label className='beige'>Join {fixedNumber} other people!</label>
        </form>
      </section> */}

        <section className='third-padding-top'>
          <button onClick={kickstarteRedirect} className='button_kickstarter'></button>
          <p className='input-paragraph'>Join {fixedNumber} other people!</p>
        </section>

        <main>

          <div className='third_races_container'>
            <div className='sub-container_races'>
              <p className='races_title'>Discover a vibrant realm brimming with wonders, where incredible races come to life. Master a <b>unique combat system</b> and <b>challenge epic foes in your quest for glory.</b></p>
              <section className='races_characters-container'>
                <div className='third_cards'>
                  <div className='third_card-flecha dos'>Flecha2</div>
                  <Icon icon="ic:baseline-arrow-back-ios-new" />
                  <Swiper
                    effect={'cards'}
                    grabCursor={true}
                    className="mySwiper"
                    modules={[EffectCards]}
                  >
                    <SwiperSlide><img className='races_img' src="./third/png/races_aquans.png"></img></SwiperSlide>
                    <SwiperSlide><img className='races_img' src="./third/png/races_axolotl.png"></img></SwiperSlide>
                    <SwiperSlide><img className='races_img' src="./third/png/races_elves.png"></img></SwiperSlide>
                    <SwiperSlide><img className='races_img' src="./third/png/races_embear.png"></img></SwiperSlide>
                    <SwiperSlide><img className='races_img' src="./third/png/races_fairans.png"></img></SwiperSlide>
                    <SwiperSlide><img className='races_img' src="./third/png/races_foxys.png"></img></SwiperSlide>
                    <SwiperSlide><img className='races_img' src="./third/png/races_trolls.png"></img></SwiperSlide>
                    <SwiperSlide><img className='races_img' src="./third/png/races_goblins.png"></img></SwiperSlide>
                  </Swiper>
                  <Icon icon="ic:baseline-arrow-forward-ios" />
                  <div className='third_card-flecha'>Flecha1</div>
                </div>
                <div className='third_cards-static'></div>
                <div className='races_text-right'>
                  <div className='races-format'>
                    <p className='races_number'>8</p>
                    <div className='races_text-left'>
                      <p className='races_text work-sans'>unique magical</p>
                      <div className='races_to-play'>
                        <p className='races_text text-bork beige'>Races</p>
                        <small>to <br></br> play</small>
                      </div>
                    </div>
                  </div>
                  <p className='each-race work-sans'>Each race has <br></br> special attributes, abilities, <br></br> and passive skills</p>
                </div>
              </section>
            </div>
            <p class="third_races_bottom-text dorado work-sans">You become what you specialize in: There won't be a predetermined class system; <b>you determine your class through your upgrades, armor, and weapons.</b> </p>
          </div>

          {/* <section className='third_proffesions'>

</section> */}

          <section className='third_biomes_container'>
            <div className="biomes_text">
              <p className='third_biomes_number beige bork'>10</p>
              <div className='biomes_text-right'>
                <p className='biomes_text-right-p white work-sans bold'>completely unique</p>
                <p className='biomes_text-right-p bork beige'>Fantasy biomes</p>
              </div>
            </div>
            <img src="./third/png/map.png"></img>
            <p className='third_biomes-bottom-text'>Private property to <b className='beige'>use and decorate as you like:</b> Create an alchemy potion bar, use your lake for fish farming, have the biggest harvest, and more!</p>
          </section>
          <section className='third_consoles'>
            <img src="./third/png/consolasgrandea.png" alt="" />
          </section>
          {/* <section className='third_videos_container'>
        <p className='white'>Private property to <b className='beige'>use and decorate as you like: </b> Create an alchemy potion bar, use your lake for fish farming, have the biggest harvest, and more!</p>
        <div className='third-videos'>
        <ModalImage
        small={'./third/monitorsintexto/landing_02b.png'}
        large={'/third/monitorsintexto/landing_02b.png'}
        alt="Hello World!"
        />;
        <div className='video'></div>
        <div className='video'></div>
        </div>
      </section> */}

          <section className='third_lands_container'>
            <p className='third_lands-title beige bork bold'>MIXED <br></br> SKILL TREES <span className='work-sans'>of</span></p>
            <p className='third_lands-subtitle dorado'>elements, weapons and professions.</p>
            <div className='third_lands-img-container'>
              <div className="skill-tree_div">
                <img src="./third/png/skill_tree.png" alt="" />
              </div>
              <small className='third_lands-skilltree_small morado'>First concept of skill three</small>
            </div>
          </section>

          <section className='third_open-world'>
            <img src="./third/monitor/landing_08.png" alt="" />
          </section>
          <section className='third_gameplay'>
            <img src="./third/movil/joystick.png" alt="" />
          </section>


          <section className='third_elements_png'>
          </section>

          <section className='third_originalstory'>

          </section>



          {/* <section className='third-input_email'>
        <form>
        <label>Add your email address</label>
        <div>
        <input type='email' placeholder='name@my_emailaddress.com'></input>
        <input type='submit' value="Sign up"></input>
        </div>
        <label className='beige'>Join {fixedNumber} other people!</label>
        </form>
      </section> */}

          {/* <section className='third_email-section'>
        <p>Can we count on your pledge for our upcoming project?</p>
        <form>
        <label>Add your email address</label>
        <div>
        <input type='email' placeholder='name@example.com'></input>
        <input type='submit' value="Sign up"></input>
        </div>
        <p>Join {fixedNumber} other people!</p>
        </form>
      </section> */}
        </main>
      </div>

      <div className='third-bottom_container'>
        <section className=''>
          <button onClick={kickstarteRedirect} className='button_kickstarter'></button>
          <p className='input-paragraph'>Join {fixedNumber} other people!</p>
        </section>

        <section className='third_get-ready'>
          <p className='negro'>Get Ready for Secret Forest</p>
        </section>

        <footer>
          All Right Reserved - Zelcar Games
        </footer>
      </div>
    </>
  )
}

export default ThirdLanding