import React, { useState, useLocation } from 'react';
import ReactDOM from 'react-dom/client';
import { Router, Route, BrowserRouter, HashRouter } from "react-router-dom";
import AppRouter from './Routes/AppRouter'
import './styles/globals.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HashRouter>
      <AppRouter />
    </HashRouter>
);