import React, { useEffect, useState } from "react";
import styles from "./styles/fourthlanding.module.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, EffectCards } from "swiper/modules";
import { Icon } from "@iconify/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const FourthLandingPage = () => {
  const [emailCounter, setEmailCounter] = useState(0);
  const [captchaState, setCaptchaState] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const sendFirstEmail = (e) => {
    e.preventDefault();
    console.log("IsValidEmail: ", isValidEmail(emailInput));
    if (captchaState && isValidEmail(emailInput)) {
      axios
        .post(
          `https://hammerhead-app-i4xs5.ondigitalocean.app/api/auth/register`,
          { email: emailInput }
        )
        .then(() => {
          sendSecondEmail();
        })
        .catch((error) => {
          console.error("Error al enviar el email:", error);
          toast.error("The email is already registered", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    } else {
      if (captchaState == false) {
        toast.error("Please verify with captcha.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      if (isValidEmail(emailInput) == false || emailInput == "") {
        toast.error("The email is wrong.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };
  const sendSecondEmail = () => {
    axios
      .post(
        "https://hammerhead-app-i4xs5.ondigitalocean.app/api/secretForestEmails/sendEmail",
        { emailTo: emailInput }
      )
      .then(() => {
        getEmailsCounter();
        toast.success("Thank you for signing up!", {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        // setTimeout(() => {
        //   toast.info(
        //     "Now sign up for Kickstarter to be among the first to know about the campaign launch!",
        //     {
        //       position: "top-center",
        //       autoClose: 4000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //       theme: "dark",
        //     }
        //   );
        // }, 2000);
        setTimeout(() => {
          setModalState(true);
        }, 1000);
        setTimeout(() => {
          window.location.href =
            "https://www.kickstarter.com/projects/secretforest/secretforest";
        }, 7500);
        setEmailInput("");
      })
      .catch((error) => {
        toast.error("Internal server error sending email!", {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.error(error);
      });
  };
  const getEmailsCounter = () => {
    axios
      .get(`https://hammerhead-app-i4xs5.ondigitalocean.app/api/auth/cuantity`)
      .then((response) => {
        setEmailCounter(response.data.emailCuantity + 18450);
      });
  };
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  useEffect(() => {
    getEmailsCounter();
  }, []);

  return (
    <main className={styles.container}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {modalState == true && (
        <>
          <div className="modalBackground"></div>
          <div className="mountModalContainer mountModalContainerFourthPage">
            <button
              className="mountModalCloseBtn mountModalCloseBtnFourthPage"
              onClick={() => setModalState(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 32 32"
              >
                <path d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m0 26C9.4 28 4 22.6 4 16S9.4 4 16 4s12 5.4 12 12s-5.4 12-12 12" />
                <path d="M21.4 23L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z" />
              </svg>
            </button>
            <img
              src="/images/mountPopUp/halcon.png"
              alt="Mount"
              className="mountModalImage"
            />
            <p className="mountModalText mountModalTextFourthPage">
              <strong>
                Get the exclusive pre-launch Hippogriff mount in just two steps:
              </strong>
            </p>
            <p className="mountModalText2 mountModalText2FourthPage">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M10 8.64v6.72L15.27 12z"
                  opacity=".3"
                />
                <path
                  fill="currentColor"
                  d="m8 19l11-7L8 5zm2-10.36L15.27 12L10 15.36z"
                />
              </svg>
              First Step: Sign up for our Kickstarter waitlist to be among the
              first to know when the campaign launches! You are
              being redirected.
            </p>
            {/* <p className="mountModalText2 mountModalText2FourthPage">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M10 8.64v6.72L15.27 12z"
                  opacity=".3"
                />
                <path
                  fill="currentColor"
                  d="m8 19l11-7L8 5zm2-10.36L15.27 12L10 15.36z"
                />
              </svg>
              Join our Discord and show a screeshot of your Kickstarter waitlist
              registration.
            </p> */}
            {/* <div className="mountModalButtons">
              <a href="https://www.kickstarter.com/projects/secretforest/secretforest">
                <img
                  src="/images/mountPopUp/boton_kickstarter.png"
                  alt="Kickstarter button"
                />
              </a>
              <a href="https://discord.com/invite/wwf6XgeHKh">
                <img
                  src="/images/mountPopUp/boton_discord.png"
                  alt="Discord button"
                />
              </a>
            </div> */}
          </div>
        </>
      )}
      {/*
      {modalState == true && (
        <>
          <div className={styles.modalBackground}></div>
          <div className={styles.mountModalContainer}>
            <button
              className={styles.mountModalCloseBtn}
              onClick={() => setModalState(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 32 32"
              >
                <path
                  fill="#ffff"
                  d="M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14s14-6.2 14-14S23.8 2 16 2m0 26C9.4 28 4 22.6 4 16S9.4 4 16 4s12 5.4 12 12s-5.4 12-12 12"
                />
                <path
                  fill="#ffff"
                  d="M21.4 23L16 17.6L10.6 23L9 21.4l5.4-5.4L9 10.6L10.6 9l5.4 5.4L21.4 9l1.6 1.6l-5.4 5.4l5.4 5.4z"
                />
              </svg>
            </button>
            <div className={styles.mountModalRight}>
              <img src="/images/mountPopUp/gatocaballo.png" alt="Mount" />
            </div>
            <div className={styles.mountModalLeft}>
              <p className={styles.mountModalText}>
                <strong>
                  Get the exclusive pre-lauch campaign mount in just two steps:
                </strong>
              </p>
              <p className={styles.mountModalText}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                >
                  <g transform="rotate(90 12 12)">
                    <path
                      fill="white"
                      d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19"
                    />
                  </g>
                </svg>
                Sign up for our projects Kickstarter waitlist.
              </p>
              <p className={styles.mountModalText}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                >
                  <g transform="rotate(90 12 12)">
                    <path
                      fill="white"
                      d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19"
                    />
                  </g>
                </svg>
                Join our Discord and show a screeshot of your Kickstarter
                waitlist registration.
              </p>
              <div className={styles.mountModalButtons}>
                <a href="https://www.kickstarter.com/projects/secretforest/secretforest">
                  <img
                    src="/images/mountPopUp/boton_kickstarter.png"
                    alt="Kickstarter button"
                  />
                </a>
                <a href="https://discord.com/invite/wwf6XgeHKh">
                  <img
                    src="/images/mountPopUp/boton_discord.png"
                    alt="Discord button"
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      */}
      <header>
        <img
          className={styles.headerTitle}
          src="/fourth/secretForest.png"
          alt=""
        />
        <img
          className={styles.headerTitle2}
          src="/fourth/newMMORPG.png"
          alt=""
        />
        <div className={styles.headerDown}>
          <img
            src="/fourth/alufiLeft.png"
            className={styles.headerAlufisBattle}
            alt=""
          />
          <form onSubmit={(e) => sendFirstEmail(e)}>
            <h1>Get ready for Secret Forest</h1>
            <h2>By Zelcar Games</h2>
            <p>Help us and be the first to play the beta when it comes out!</p>
            <label>Add your email address</label>
            <div className={styles.headerForm}>
              <input
                type="email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                placeholder="name@example.com"
              />
              <button onClick={(e) => sendFirstEmail(e)}>Sign up</button>
            </div>
            <p className={styles.headerJoinPeople}>
              Join {emailCounter} other people!
            </p>
            <ReCAPTCHA
              sitekey="6LcKs2MoAAAAANbEb8FgM_zGq-AZx2SegfCCegkn"
              onChange={() => setCaptchaState(true)}
            />
          </form>
          <img
            src="/fourth/alufiRight.png"
            className={styles.headerAlufisBattle}
            alt=""
          />
        </div>
      </header>
      <div className={styles.main}>
        <section className={styles.races}>
          <p className={styles.racesTitle}>
            Discover a vibrant realm brimming with wonders, where incredible
            races come to life. Master a{" "}
            <span className={styles.racesSpan1}>unique combat system</span> and{" "}
            <span>challenge epic foes in your quest for glory.</span>
          </p>
          <div className={styles.racesImages}>
            <div className={styles.racesCardContainer}>
              <p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 1024 1024"
                >
                  <g transform="rotate(-90 512 512)">
                    <path
                      fill="#ffff"
                      d="M104.704 685.248a64 64 0 0 0 90.496 0l316.8-316.8l316.8 316.8a64 64 0 0 0 90.496-90.496L557.248 232.704a64 64 0 0 0-90.496 0L104.704 594.752a64 64 0 0 0 0 90.496"
                    />
                  </g>
                </svg>
              </p>
              <Swiper
                effect={"cards"}
                grabCursor={true}
                className={styles.mySwiper}
                modules={[EffectCards]}
              >
                <SwiperSlide>
                  <img
                    className={styles.racesImg}
                    src="./fourth/cards/aquan.png"
                  ></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={styles.racesImg}
                    src="./fourth/cards/axolotl.png"
                  ></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={styles.racesImg}
                    src="./fourth/cards/elve.png"
                  ></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={styles.racesImg}
                    src="./fourth/cards/embear.png"
                  ></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={styles.racesImg}
                    src="./fourth/cards/fairan.png"
                  ></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={styles.racesImg}
                    src="./fourth/cards/foxy.png"
                  ></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={styles.racesImg}
                    src="./fourth/cards/troll.png"
                  ></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={styles.racesImg}
                    src="./fourth/cards/goblin.png"
                  ></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={styles.racesImg}
                    src="./fourth/cards/catty.png"
                  ></img>
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={styles.racesImg}
                    src="./fourth/cards/doggy.png"
                  ></img>
                </SwiperSlide>
              </Swiper>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 1024 1024"
                >
                  <g transform="rotate(90 512 512)">
                    <path
                      fill="#ffff"
                      d="M104.704 685.248a64 64 0 0 0 90.496 0l316.8-316.8l316.8 316.8a64 64 0 0 0 90.496-90.496L557.248 232.704a64 64 0 0 0-90.496 0L104.704 594.752a64 64 0 0 0 0 90.496"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className={styles.racesImagesText}>
              <img src="/fourth/10UniqueRaces.png" alt="" />
              <p>
                Each race has special attributes,
                <br /> abilities, and passive skills
              </p>
            </div>
          </div>
          <p>
            You become what you specialize in: There won't be a predeterminated
            class system;{" "}
            <span className={styles.racesSpan2}>
              you determine your class through your upgrades, armor, and
              weapons.
            </span>
          </p>
        </section>
        <section className={styles.biomes}>
          <img
            className={styles.biomesTitle}
            src="/fourth/10FantasyBiomes.png"
            alt=""
          />
          <img className={styles.biomesMap} src="/fourth/map.png" alt="" />
          <p className={styles.biomesText1}>
            Private property to <span>use and decorate as you like:</span>{" "}
            Create an alchemy potion bar, use your lake for fish farming, have
            the biggest harvest, and more!
          </p>
          <img
            className={styles.biomesVideogames}
            src="/fourth/videogames.png"
            alt=""
          />
          <p className={styles.biomesText2}>
            It will have everything an MMORPG should have: parties, guilds,
            factions, enemies, bosses, collectibles, craftables, PvP zones, PvE
            zones, trade, etc.!
          </p>
        </section>
        <section className={styles.skillTrees}>
          <img
            className={styles.skillTreesTitle}
            src="/fourth/mixedSkillTrees.png"
            alt=""
          />
          <p className={styles.skillTreesSubtitle}>
            of <span>elements, weapons and professions</span>
          </p>
          <img
            className={styles.skillTreesDemo}
            src="/fourth/skillTreeDemo.png"
            alt=""
          />
          <p className={styles.skillTreesText1}>First concept of skill three</p>
        </section>
        <section className={styles.openWorld}>
          <img src="/fourth/150OpenWorld.png" alt="" />{" "}
        </section>
        <section className={styles.hoursGameplay}>
          <img src="/fourth/150Hours.png" alt="" />
        </section>
        <section className={styles.elements}>
          <img src="/fourth/8Elements2.png" alt="" />
        </section>
        <section className={styles.finalInfos}>
          <p className={styles.finalInfosText1}>
            We want to give the best gaming experience, so: there will be no pay
            to win, not autoplay.
          </p>
          <img src="/fourth/flyingMounts.png" alt="" />
          <p className={styles.finalInfosEngaging}>
            Engaging quest, <br /> <span> not just farming</span>
          </p>
          <p className={styles.finalInfosMuchMore}>And much more!</p>
        </section>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className={styles.footerWave}
        >
          <path
            fill="#680E70"
            fill-opacity="1"
            d="M0,256L120,256C240,256,480,256,720,229.3C960,203,1200,149,1320,122.7L1440,96L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
        <section className={styles.footerForm}>
          <form onSubmit={(e) => sendFirstEmail(e)}>
            <p>Help us and be the first to play the beta when it comes out!</p>
            <label>Add your email address</label>
            <div className={styles.footerFormInputs}>
              <input
                type="email"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                placeholder="name@example.com"
              />
              <button onClick={(e) => sendFirstEmail(e)}>Sign up</button>
            </div>
            <p className={styles.footerFormJoinPeople}>
              Join {emailCounter} other people!
            </p>
            <ReCAPTCHA
              sitekey="6LcKs2MoAAAAANbEb8FgM_zGq-AZx2SegfCCegkn"
              onChange={() => setCaptchaState(true)}
            />
            <a
              href="https://discord.com/invite/wwf6XgeHKh"
              className={styles.downDiscord}
            >
              <img
                src="/images/mountPopUp/boton_discord.png"
                alt="Discord button"
              />
            </a>
          </form>
        </section>
      </div>
    </main>
  );
};

export default FourthLandingPage;
