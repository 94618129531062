import React from 'react'
import './thanks.css'
const Thanks = () => {

    const kickstarteRedirect = () => {
        window.location.href = ('https://www.kickstarter.com/projects/secretforest/secretforest')
      }

    const discord = () => {
        window.location.href = ('https://discord.gg/RyJ4sTA644')
    } 
    const twitter = () => {
        window.location.href = ('https://twitter.com/secretforestx')
    } 
    const tiktok = () => {
        window.location.href = ('https://www.tiktok.com/@secretforestworld')
    } 
    const instagram = () => {
        window.location.href = ('https://instagram.com/secretforestworld')
    } 


    return (
        <main className='container-thanks'>
            <div className='fantasma'></div>
            <div className='thanks-logo'>
                <img src="./images/logo.png" alt="" />
            </div>

            <div className='container-text'>
                <p className='beige'>Thank you for signing up! We'll keep you updated through email about our Kickstarter campaign and the game.</p>
            </div>

            <div className='container-text'>
                <p className='beige'>It would really help us if you also signed up with your email on our Kickstarter page. This will make our project more visible and successful on Kickstarter! If you want to help with that, here is the Kickstarter link:</p>
            </div>
            <button onClick={kickstarteRedirect} className='button_thanks button_kickstarter'></button>
            <div className='container-text'>
                <p className='beige'>If you've already done that and want to learn more about the project and its daily progress, feel free to join our Discord and follow us on social media!</p>
            </div>

            <div className='redes-container_thanks'>
                <div onClick={discord} className='thanks_redes_button'>
                    <img src="./images/discord.svg" alt="" />
                </div>
                <div onClick={instagram} className='thanks_redes_button'>
                    <img src="./images/instagram.svg" alt="" />
                </div>
                <div  onClick={twitter} className='thanks_redes_button'>
                    <img src="./images/twitterx.svg" alt="" />
                </div>
                <div onClick={tiktok} className='thanks_redes_button'>
                    <img src="./images/tiktok.svg" alt="" />
                </div>
            </div>
        </main>
    )
}

export default Thanks